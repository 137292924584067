import { CircularProgress, Drawer } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import GroupIcon from '@material-ui/icons/Group';
import FormDialog from 'components/FormDialog';
import Uploader from 'components/Uploader';
import Button from 'components/inputs/ButtonM';
import IconButton from 'components/inputs/IconButton';
import { addMinutes, differenceInSeconds } from 'date-fns';
import { filter, find, flatMap, keys, map } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import { ga, useGState } from 'state/store';
import { useInterval, useBranchId } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { format0, formatDateHumanReadable, formatTimeMinSec, isVal } from 'utils/utils';
import ManageHoldRetry from './ManageHoldRetry';

export default function DeliveryOrderDetails({ data }) {
  const { id, planProfiles } = data;
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="overflow-x-auto">
      <p className="text-xl font-medium mb-2">Plan Details</p>
      {planProfiles.length === 1 ? (
        <ProfileDetails profile={planProfiles[0]} orderUploadId={id} />
      ) : (
        <div className="min-w-max">
          <Tabs
            value={selectedTab}
            onChange={(_, val) => {
              setSelectedTab(val);
            }}
          >
            {planProfiles.map((pp) => (
              <Tab label={pp.name || 'Default'} key={pp.name} />
            ))}
          </Tabs>
          <ProfileDetails profile={planProfiles[selectedTab]} orderUploadId={id} />
        </div>
      )}
    </div>
  );
}

function ProfileDetails({ profile: { profileId } = {}, orderUploadId }) {
  const settings = useGState((s) => s[ga.CLIENT_PREFERENCE]);
  const [planInProgress, setPlanInProgress] = useState(false);
  const [holdRetryTab, setHoldRetryTab] = useState(false);
  const [includeRetries, setIncludeRetries] = useState(Boolean(settings.includeRetrysInPlanning));
  const dialog = useRef();
  const [staticAssignment, setStaticAssignment] = useState();
  const branchId = useBranchId();

  useEffect(() => {
    if (settings.includeRetrysInPlanning !== includeRetries)
      setIncludeRetries(settings.includeRetrysInPlanning);
    // eslint-disable-next-line
  }, [settings.includeRetrysInPlanning]);

  const { data, refetch } = useQuery(
    ['planPreview', profileId, orderUploadId],
    () => fetch(`/v2/delivery/plan/preview?orderUploadId=${orderUploadId}&profileId=${profileId}`),
    {
      refetchInterval: planInProgress ? 10000 : false,
      onSuccess: (data) => {
        setStaticAssignment(data.profile?.data?.StaticAssignment);
      },
    }
  );

  const [createPlan] = useMutation(
    (data) =>
      fetch({
        method: 'POST',
        url: `/v2/delivery/plan/create-include-retries?orderUploadId=${orderUploadId}&profileId=${profileId}&includeRetries=${includeRetries}`,
        data,
      }),
    {
      onSuccess: () => refetch(),
    }
  );

  const [topUpRetries] = useMutation(
    (planId) =>
      fetch({
        method: 'POST',
        url: `/v2/delivery/plan/retry-topup?planId=${planId}`,
      }),
    {
      onSuccess: () => refetch(),
    }
  );

  useEffect(() => {
    if (data) {
      const {
        plan: { planStatus },
        profile: { wave1Profile },
      } = data;
      setPlanInProgress(planStatus && planStatus !== 'frozen') || isVal(wave1Profile);
    }
  }, [data]);

  const retryRetailersCount = useMemo(() => {
    if (!data?.retryInvoices) return 0;
    const retryRetailers = new Set();
    data.retryInvoices.forEach(({ retailerCode }) => {
      retryRetailers.add(retailerCode);
    });
    return [...retryRetailers].length;
  }, [data]);

  const profileSalesmenOptions = useMemo(
    () => data?.profileSalesmen?.map((ps) => ({ label: ps.code, value: ps.id })) ?? [],
    [data]
  );

  if (!data) return null;

  const {
    orderStats: { dseCount, orderCount, retailers, sales, weight },
    plan: { vehiclesCount, planStatus, planId, planCreatedAt },
    profile: { wave1Profile, static: staticProfile },
    retryInvoices,
  } = data;

  function isCreateAllowed() {
    if (isVal(wave1Profile)) return false;
    if (planStatus) return false;
    return true;
  }

  const openHoldRetryTab = () => {
    setHoldRetryTab(true);
  };

  const returnTerminology = settings.returnTerminology;
  return (
    <div>
      <div class="grid grid-cols-7 gap-3 mt-8">
        <LabelValue label="Salesman" value={dseCount} />
        <LabelValue label="Customers" value={retailers} />
        <LabelValue label="Retry Customers" value={retryRetailersCount} />
        <LabelValue label="Orders" value={orderCount} />
        <LabelValue label="Sales" value={sales} />
        <LabelValue label="Weight" value={`${format0(weight)} kg`} />
        <LabelValue label="Vehicle Count" value={vehiclesCount} />
      </div>
      <div class="flex justify-between mt-8 items-end">
        <p class="text-lg">Included Retries</p>
        <Button onClick={openHoldRetryTab}>
          {`Manage ${returnTerminology?.HOLD || 'Hold'} / ${returnTerminology?.RETRY || 'Retry'}`}{' '}
        </Button>
        <Drawer anchor="right" open={holdRetryTab} onClose={() => setHoldRetryTab(false)}>
          {<ManageHoldRetry closeTab={() => setHoldRetryTab(false)} />}
        </Drawer>
      </div>
      {includeRetries && (
        <div class="grid grid-cols-3 gap-3 mt-7">
          {retryInvoices.map((retry) => (
            <RetryCard data={retry} key={retry.invoiceCode} />
          ))}
        </div>
      )}
      <div class="flex justify-center items-center mt-10">
        {isCreateAllowed() && (
          <div class="ml-4">
            {staticProfile ? (
              <div className="flex align-middle">
                <IconButton onClick={() => dialog.current.openDialog()}>
                  <GroupIcon />
                </IconButton>
                <Uploader
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  url={`/v2/delivery/plan/create-include-retries?orderUploadId=${orderUploadId}&profileId=${profileId}&includeRetries=${includeRetries}`}
                  onSuccess={() => refetch()}
                  render={(_, apiInProgress) => (
                    <div class="flex items-center">
                      <div class="w-8 mr-2">{apiInProgress && <CircularProgress size={24} />}</div>
                      <Button disabled={apiInProgress} component="span">
                        Upload Static Plan
                      </Button>
                    </div>
                  )}
                />
              </div>
            ) : (
              <Button onClick={() => createPlan()}>Create Plan</Button>
            )}
          </div>
        )}
        <div class="ml-4">
          <Button
            disabled={!planStatus}
            onClick={() => window.open(`/dl/plan/${planId}?branchId=${branchId}`)}
          >
            Edit Plan
          </Button>
        </div>
        <div class="ml-4">
          <Button
            disabled={planStatus !== 'frozen'}
            href={`/api/v2/delivery/plan/download?planId=${planId}`}
          >
            Download
          </Button>
        </div>
        {settings?.erpType === 'LEAP' && (
          <div class="ml-4">
            <Button
              disabled={planStatus !== 'frozen'}
              href={`/api/v2/delivery/plan/download/generic?planId=${planId}`}
            >
              Download Generic
            </Button>
          </div>
        )}
        {settings?.allowTopUpRetrys && (
          <div class="ml-4">
            <Button disabled={planStatus !== 'frozen'} onClick={() => topUpRetries(planId)}>
              Topup Retries
            </Button>
          </div>
        )}

        {!planStatus && !settings?.tms.orderTrayEnabled && (
          <div class="ml-4">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeRetries}
                    onChange={() => setIncludeRetries((ir) => !ir)}
                    color="primary"
                  />
                }
                label="Include Retries"
              />
            </FormGroup>
          </div>
        )}
        {settings?.wmsEnabled && (
          <div class="ml-4">
            <Button href={`/api/bwms/picklist?planId=${planId}`}>Download SKU Picklist</Button>
          </div>
        )}
      </div>
      {planInProgress && <Timer planCreatedAt={planCreatedAt} />}
      <FormDialog
        title="Salesman Assignment"
        content={
          <SalesmanVehicleMapping
            staticAssignment={staticAssignment}
            profileSalesmenOptions={profileSalesmenOptions}
            setStaticAssignment={setStaticAssignment}
          />
        }
        ref={dialog}
        submit="Create Plan"
        fullWidth
        maxWidth="md"
        onSubmit={() => {
          const payLoad = {
            salesmanAssignments: keys(staticAssignment).flatMap((key) =>
              staticAssignment[key].map((salesmanCode) => ({
                picklistIndex: Number(key) - 1,
                salesmanId: find(profileSalesmenOptions, { label: salesmanCode })?.value,
                vehicleTypeId: data?.profile?.vehicleTypes?.[0]?.id,
              }))
            ),
          };
          payLoad.salesmanAssignments = filter(payLoad.salesmanAssignments, 'salesmanId');
          createPlan(payLoad);
        }}
      />
    </div>
  );
}

function SalesmanVehicleMapping({ staticAssignment, profileSalesmenOptions, setStaticAssignment }) {
  const selected = flatMap(staticAssignment);
  return (
    <div className="grid grid-cols-2 gap-8 mb-72">
      {keys(staticAssignment).map((key) => {
        return (
          <div className="flex w-full items-center" key={key}>
            <div className="mr-3 text-lg">V{key}</div>
            <Select
              options={profileSalesmenOptions.map((o) => ({
                ...o,
                isDisabled: selected.includes(o.label),
              }))}
              className="w-56"
              // isOptionDisabled={(opt) => selected.includes(opt.label)}
              isMulti
              onChange={(val) =>
                setStaticAssignment((s) => ({ ...s, [key]: map(val ?? [], 'label') }))
              }
              value={filter(profileSalesmenOptions, (opt) =>
                staticAssignment[key].includes(opt.label)
              )}
            />
          </div>
        );
      })}
    </div>
  );
}

function Timer({ planCreatedAt }) {
  const planETA = planCreatedAt && addMinutes(new Date(planCreatedAt), 20);
  const [timeLeft, setTimeLeft] = useState(differenceInSeconds(planETA, new Date()));

  useInterval(() => {
    setTimeLeft(differenceInSeconds(planETA, new Date()));
  }, 1000);

  return (
    <div class="w-full text-center mt-4">
      <p class="text-lg font-medium">{`Creating the Plan ${
        timeLeft > 0 ? formatTimeMinSec(timeLeft) : '--:--'
      }`}</p>
    </div>
  );
}

function LabelValue({ label, value }) {
  return (
    <div class="shadow-md rounded-lg text-center p-4">
      <p class="font-medium text-xl">{value || 0}</p>
      <p class="mt-1 text-secondary text-xs">{label}</p>
    </div>
  );
}

function RetryCard({ data }) {
  const { invoiceCode, date, retailerCode, salesmanCode, value } = data;
  return (
    <div class="shadow-md rounded-lg p-8">
      <div class="flex justify-between">
        <p class="text-lg"> {invoiceCode}</p>
        <div class="text-right">
          <p class="text-xs text-secondary">Assigned On</p>
          <p class="text-xs">{formatDateHumanReadable(new Date(date))}</p>
        </div>
      </div>
      <p class="text-sm  mt-2">{retailerCode}</p>
      <div class="flex">
        <span class="text-sm text-secondary">Salesman code:</span>
        <p class="text-sm ml-1">{salesmanCode}</p>
      </div>
      <p class="text-xl mt-8">{value}</p>
    </div>
  );
}
